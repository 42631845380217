<template>
    <div class="offer-wrapper uk-animation-fade">
        <!-- TODO: ecommerce layout for header wrapper sidebar ETC -->
        <ecommerce-hero
                class="uk-animation-fade"
                title="Prestige - brighten up your daily life"
                :background="heroBackground">
        </ecommerce-hero>


        <div class="uk-container-large uk-margin-auto uk-padding-small uk-animation-fade">
            <ul class="uk-breadcrumb">
                <li><a href="#shop">Shop</a></li>
                <li><a href="#shop">Offers</a></li>
                <li class="uk-disabled"><a>Prestige</a></li>
            </ul>
        </div>

        <div class="uk-container-large uk-margin-auto uk-margin-large-bottom uk-padding-small">
            <div class="ecommerce category">

                <h1 class="uk-text-center uk-margin-remove-bottom">Prestige offers</h1>
                <h3 class="uk-margin-remove-top uk-text-center">Sub heading for plan category, text editable</h3>
                <div class="packages-external uk-margin-large-bottom">
                    <div class="packages-outer"  >
                        <div class="packages-slider-inner uk-position-relative  ">
                            <div class="uk-slider-container">
                                <ul class="packages uk-slider-items uk-overflow-visible ">
                                    <div class=" package package-box uk-margin-small-right uk-padding-small uk-flex uk-width-1-1 uk-width-1-2@m  uk-width-1-4@l"
                                         v-for="(plan, index) of packages" > <!-- v-for="plan of packages" :key="plan" -->
                                        <div class="info  uk-card uk-card-default uk-card-body uk-box-shadow-medium uk-padding-remove">
                                            <div class="uk-card-hat uk-background-secondary uk-text-inverse-color uk-text-center">
                                                <span class=" uk-text-inverse-color uk-margin-remove">{{plan.title}}</span>
                                            </div>

                                            <div class="package-content uk-padding uk-padding-remove-vertical uk-margin-bottom">
                                                <div class="package-summary uk-margin-bottom">
                                                    <div class="step-product-details-price ">
                                                        <div class="content">
                                                            <div><span class="package-limit uk-text-bold">{{plan.limitText}}</span></div>
                                                            <div><span class="">0 - 24 month commitment</span></div>

                                                        </div>
                                                        <div class="price uk-margin-bottom">
                                                            <h3 class="uk-margin-remove-bottom ">
                                                                <strong data-v-749a5bba="">155 F / Month</strong>
                                                            </h3>
                                                            <span class="uk-text-small" >x12 months then, 220 F/MO</span>
                                                        </div>
                                                        <div class="cta-wrapper uk-flex uk-flex-center">
                                                            <form-button type="primary"
                                                                         :fullWidth="true"
                                                                         @click.prevent="">Choose</form-button>

                                                        </div>


                                                    </div>
                                                </div>
                                                <hr class="package-info-separator">
                                                <div class="package-highlights">
                                                    <div class="package-features uk-margin-bottom">
                                                        <h4 class="uk-text-secondary uk-text-semibold uk-margin-small-bottom">Included in this package</h4>
                                                        <ul class="uk-list uk-list-primary uk-list-disc uk-margin-remove">
                                                            <li v-for="include in plan.includes">{{include}}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="package-detail uk-margin-large-bottom  ">
                                                        <h4 class="uk-text-secondary uk-text-semibold uk-margin-small-bottom ">Package Advantages</h4>
                                                        <ul class="uk-list advantages-list uk-list-primary uk-list-disc uk-margin-remove">
                                                            <li v-for="benefit in plan.benefits">
                                                                <a :href="benefit.link">{{benefit.label}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ul>
                            </div>


                            <a class="uk-position-center-left-out"  uk-slidenav-previous uk-slider-item="previous"></a>
                            <a class="uk-position-center-right-out" uk-slidenav-next uk-slider-item="next"></a>

                        </div>
                    </div>
                    <div class="general-small-print uk-text-small uk-text-center uk-margin-top">
                        <span>* some small print text that is required by law and regulation. </span><br>
                        <span>* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda at aut consequuntur, esse explicabo, fugiat ipsum itaque molestiae.</span><br>
                        <span>(1) text that is required by la elit. sit amet, consectetur adipisicing elit.</span>
                    </div>
                </div>

                <div class="main-benefits uk-margin-large-bottom">
                    <h2 class="h2 uk-text-primary uk-font-bold">Main benefit highlight</h2>
                    <div class="uk-panel uk-padding uk-padding-remove-vertical uk-text-center uk-background-primary uk-text-inverse-color">
                        <div class="panel-inner uk-margin-large-bottom uk-margin-small-top">
                            <h3 class="uk-text-inverse-color uk-margin-small-bottom" style="font-size:calc(var(--global-font-size) * 2);">4GB option for 2 500 F!</h3>
                            <div class="uk-container-xsmall uk-margin-auto">
                                <p class="uk-margin-remove-bottom">Some first line explaining the feature!<br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad, architecto consectetur distinctio dolores ex illo illum ipsam iusto magni nemo*.</p>
                                <div class="space uk-margin-bottom"></div>
                            </div>
                        </div>

                    </div>
                    <div class="small-print uk-text-center"><span class="uk-text-small">* Some small print must be here due to legal reasons.</span></div>

                </div>

                <div class="other-benefits uk-margin-large-bottom">
                    <h2 class="h2 uk-text-primary uk-font-bold">Benefits</h2>
                    <div class="benefits-grid uk-grid-match uk-flex uk-flex-between uk-child-width-expand@l uk-text-center uk-child-width-1-1@s uk-child-width-1-2@m " uk-grid>
                        <div class="benefit-wrapper ">
                            <div class="benefit uk-panel uk-background-primary uk-text-inverse-color uk-padding-small uk-box-shadow-large">
                                <h3 class="uk-text-inverse-color uk-text-strong">
                                    Primary highlight
                                </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dolore libero nemo nesciunt quas, quis similique sunt unde? Aperiam dicta facere inventore labore laborum molestiae mollitia nostrum, qui quia velit!</p>
                            </div>
                        </div>
                        <div class="benefit-wrapper ">
                            <div class="benefit uk-panel uk-background-muted uk-padding-small uk-box-shadow-large">
                                <h3 class=" uk-text-strong">
                                    Additional hightlight
                                </h3>
                                <p>Benefit paragraph with a reasonable length, Benefit paragraph with a reasonable length</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias dignissimos harum molestias nemo neque odit perspiciatis sed. Accusamus dolorum, error facere laborum nostrum quae quibusdam sed. Fugiat, quam, ratione.</p>
                            </div>
                        </div>

                        <div class="benefit-wrapper ">
                            <div class="benefit uk-panel uk-background-muted uk-padding-small uk-box-shadow-large">
                                <h3 class=" uk-text-strong">
                                    Additional hightlight
                                </h3>
                                <p>Benefit paragraph with a reasonable length, Benefit paragraph with a reasonable length</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias dignissimos harum molestias nemo neque odit perspiciatis sed. Accusamus dolorum, error facere laborum nostrum quae quibusdam sed. Fugiat, quam, ratione.</p>
                            </div>
                        </div>

                        <div class="benefit-wrapper ">
                            <div class="benefit uk-panel uk-background-muted uk-padding-small uk-box-shadow-large">
                                <h3 class=" uk-text-strong">
                                    Additional highlight
                                </h3>
                                <p>Benefit paragraph with a reasonable length, Benefit paragraph with a reasonable length</p>
                            </div>
                        </div>
                    </div>
                    <div class="uk-panel uk-background-secondary uk-padding uk-text-center uk-width-expand uk-text-inverse-color">
                        <h2 class="uk-text-inverse-color uk-margin-remove-bottom uk-width-expand">Primary CTA </h2>
                        <div class="uk-container-xsmall  uk-margin-auto uk-margin-top">
                            <form-button size="large" :full-width="true" class="uk-text-large " icon-ratio="1.8" icon-end="chevron-right">TAKE ACTION</form-button>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>


</template>

<script>

    import asyncOperations from "@/client/extensions/composition/asyncOperations";
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";

    export default {
        props: {
            categoryAlias: {
                type: [String, Boolean],
                default: 'devices'
            },
            heroBackground: {
                type: [String],
                default: 'ecommerce/headers/sample_header_3.jpg'
            }
        },
        components: {
            ecommerceHero
        },
        data: function () {
            return {
                packages: {
                    0 : {
                        title: 'Prestige 6h',
                        subTitle: '<strong>Something to highlight</strong> with this package and 24 month commitment',
                        dataMethod: 'FLAT RATE 4G',
                        limitText: '8Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '8GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some other benefits of package'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 150
                            },
                            1: {
                                period: 24,
                                price: 199,
                            }
                        }
                    },
                    1 : {
                        title: 'Prestige 8h',
                        subTitle: '<strong>Something else to highlight</strong> some other text',
                        dataMethod: 'FLAT RATE 4G & 5G',
                        limitText: '22Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '22GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some benefit of package'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 900
                            },
                            1: {
                                period: 24,
                                price: '1 200',
                            }
                        }
                    },
                    2 : {
                        title: 'Prestige 12h',
                        subTitle: '<strong>Something to highlight</strong> with this package and 24 month commitment',
                        dataMethod: 'FLAT RATE 4G',
                        limitText: '10Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '10GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some benefit of package'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 99
                            },
                            1: {
                                period: 24,
                                price: 350,
                            }
                        }
                    },
                    3 : {
                        title: 'Prestige 8 p.m.',
                        subTitle: '<strong>Highlight</strong> Text for the highlight',
                        dataMethod: 'FLAT RATE 4G',
                        limitText: '1Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '1GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some Special Benefit'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 375
                            },
                            1: {
                                period: 24,
                                price: 499,
                            }
                        }
                    },
                },
            };
        },
        computed: {


        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">

    .package-box {
        .package-summary {
            .package-limit{
                font-size: calc(var(--global-font-size) * 2.4);

            }
            .content {
                margin-bottom: calc(var(--global-margin) * 2);
            }
        }
        .package-info-separator {
            margin-top: calc(var(--global-margin) * 1.5);
            margin-bottom: calc(var(--global-margin) * 1.5);

        }
    }

</style>
